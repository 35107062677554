.background {
  position: fixed;

  //   background: url("./content/main.jpg") no-repeat;
  // background: url("./content/back.jpg") no-repeat;
  background: url("./assets/pictures/cover.jpg") no-repeat;
  // background: url("./assets/pictures/ex-visuel-low.jpg") no-repeat;
  background-position: right;
  background-size: cover;
  filter: blur(5px);
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

/* .background {
  width: 100vw;
  height: 100vh;
  perspective: 1200px;
  background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0) 75%
    )
    center center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 0;
}
 */

.gradient-white {
  position: fixed;
  // position: fixed;
  width: 100vw;
  height: 100vh;

  background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0) 90%
    )
    center center;

  /* background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0) 75%
    )
    center center; */
  /*   background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      #2fff06 75%
    )
    center center; */
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
}
