/* .logo img {
  position: fixed;
  max-height: 95vh;
  max-width: 95vw;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  mix-blend-mode: difference;
}
 */

.logo {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: hard-light;
}

.logo-ex img {
  position: fixed;
  left: -10%;
  top: -10%;
  max-width: 80vw;
  width: auto;
  height: auto;
}

/* .logo-01 img {
  position: fixed;
  right: 0%;
  bottom: 0%;
  max-width: 30vw;
  width: auto;
  height: auto;
} */

/* .logo-ex img {
  position: fixed;
  left: -10%;
  top: -10%;
  max-width: 60vw;
  width: auto;
  height: auto;
} */

.logo-01 img {
  position: fixed;
  right: -5%;
  bottom: -5%;
  max-width: 50vw;
  width: auto;
  height: auto;
}
