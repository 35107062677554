/* .text {
  position: absolute;
  max-width: 80vw;
  width: 75%;
  font-size: 4vw;
  color: var(--mainColor);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
} */

.text {
  position: absolute;
  //max-width: 30vw;
  // width: 30%;
  font-size: 5vw;

  width: 50%;
  z-index: 2;

  //color: greenyellow;
  //color: greenyellow;
  color: var(--thirdColor);

  mix-blend-mode: difference;
  //color: var(--mainColor);

  transition: var(--transition);
  pointer-events: none;

  h1 {
    backdrop-filter: var(--backdropBlur);
    display: inline-block;
  }
  /* 
  &:hover {
    color: white;
    transform: scale(1.01);
  } */
}

.text01 {
  top: 5%;
  left: 5%;
}

.text02 {
  bottom: 5%;
  right: 5%;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .text {
    font-size: 7vw;
  }
}
