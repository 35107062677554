.webdev {
  font-size: 1.3vw;
  position: absolute;
  z-index: 2;
  bottom: 2%;
  right: 1%;
  mix-blend-mode: difference;
  transform: rotate(-180deg);

  // color: var(--mainColor);
  //display: inline;
  writing-mode: vertical-rl;
  color: var(--thirdColor);
  backdrop-filter: blur(50px);

  a {
    text-decoration: none;
    transition: var(--transition);
    color: var(--thirdColor);
    &:hover {
      //   color: var(--secondColor);
      //  text-decoration: underline;
      color: var(--secondColor);
    }
  }
}

@media only screen and (max-width: 768px) {
  .webdev {
    font-size: 3vw;
    bottom: unset;
    top: 2%;
    right: 2%;
  }
}
