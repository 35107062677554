.book-container {
  position: relative;
  // position: fixed;
  width: 100vw;
  height: 100vh;
  perspective: 1200px;
  /*   background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0) 90%
    )
    center center; */

  /* background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0) 75%
    )
    center center; */
  /*   background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.75) 0%,
      #2fff06 75%
    )
    center center; */
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
  /*   filter: grayscale(100);
 */
  pointer-events: none;
}

.book-holder {
  transform: rotateY(10deg);
  -webkit-animation: 20s rotatingAnimation linear infinite;
  animation: 20s rotatingAnimation linear infinite;
  transform-style: preserve-3d;
  pointer-events: all;
}

.book-holder > div {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
}

.book-holder:hover > * {
  /*   opacity: 0.8; */
  transition: all 1s ease-in-out;
  cursor: pointer;
}

.book--front {
  width: calc(2 * var(--size));
  height: calc(3 * var(--size));
  background: url("./assets/pictures/main-1000.jpg") no-repeat center right;
  background-size: auto 100%;
  transform: translate3d(calc(-1 * var(--size)), calc(-1.5 * var(--size)), 0)
    translate3d(0, 0, 0) rotateY(0deg);
}

.book--back {
  width: calc(2 * var(--size));
  height: calc(3 * var(--size));
  background: url("./assets/pictures/main-1000.jpg") no-repeat center left;
  background-size: auto 100%;
  transform: translate3d(calc(-1 * var(--size)), calc(-1.5 * var(--size)), 0)
    translate3d(0, 0, calc(-0.16 * var(--size))) rotateY(180deg);
}

.book--side-left {
  width: calc(0.16 * var(--size));
  height: calc(3 * var(--size));

  background: url("./assets/pictures/main-1000.jpg") no-repeat center center;
  background-size: auto 100%;
  transform: translate3d(calc(-1 * var(--size)), calc(-1.5 * var(--size)), 0)
    translate3d(calc(-0.08 * var(--size)), 0, calc(-0.08 * var(--size)))
    rotateY(-90deg);
}

.book--side-right {
  width: calc(0.16 * var(--size));
  height: calc(3 * var(--size));
  -webkit-filter: brightness(2);
  filter: brightness(2);
  background: url("./assets/pictures/paper-vertical.jpeg") no-repeat center
    center;
  background-size: cover;
  transform: translate3d(calc(-1 * var(--size)), calc(-1.5 * var(--size)), 0)
    translate3d(calc(1.92 * var(--size)), 0px, calc(-0.08 * var(--size)))
    rotateY(90deg);
}

.book--top {
  width: calc(2 * var(--size));

  height: calc(0.16 * var(--size));
  -webkit-filter: brightness(2);
  filter: brightness(2);
  background: url("./assets/pictures/paper-vertical.jpeg") no-repeat center
    center;
  background-size: cover;
  transform: translate3d(calc(-1 * var(--size)), calc(-1.5 * var(--size)), 0)
    translate3d(0px, calc(-0.08 * var(--size)), calc(0.08 * var(--size)))
    rotateX(90deg);
}

.book--bottom {
  width: calc(2 * var(--size));

  height: calc(0.16 * var(--size));
  -webkit-filter: brightness(2);
  filter: brightness(2);
  background: url("./assets/pictures/paper-vertical.jpeg") no-repeat center
    center;
  background-size: cover;
  transform: translate3d(calc(-1 * var(--size)), calc(-1.5 * var(--size)), 0)
    translate3d(0px, calc(2.92 * var(--size)), calc(-0.08 * var(--size)))
    rotateX(-90deg);
}

@-webkit-keyframes rotatingAnimation {
  0% {
    transform: rotateX(16deg) translate3d(0, 0, 0px) rotateY(0deg);
  }

  100% {
    transform: rotateX(16deg) translate3d(0, 0, 0px) rotateY(360deg);
  }
}

@keyframes rotatingAnimation {
  0% {
    transform: rotateX(16deg) translate3d(0, 0, 0px) rotateY(0deg);
  }

  100% {
    transform: rotateX(16deg) translate3d(0, 0, 0px) rotateY(360deg);
  }
}
