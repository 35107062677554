.loader {
  /* background: url("./assets/pictures/cover.jpg") no-repeat;
  background-position: right;
  background-size: cover;
  filter: blur(10px); */
  background-color: #333;

  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  transition-timing-function: ease-in-out;

  img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 20vw;
    max-height: 30vh;
    height: auto;
    width: auto;
  }

  .gradient {
    background: radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0) 90%
      )
      center center;
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
}

.loader.hidden {
  -webkit-animation: fadeOut 1.5s;
  animation: fadeOut 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flicker-loader {
  -webkit-animation: flickerAnimation ease-in-out 3s infinite;
  animation: flickerAnimation ease-in-out 3s infinite;
}

@media screen and (max-width: 768px) {
  .textloader {
    font-size: 6vw;
  }
}
