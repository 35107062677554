#buttons {
  position: absolute;
  font-size: 1.7vw;
  bottom: 2%;
  left: 1%;
  z-index: 2;
  mix-blend-mode: difference;

  li {
    display: inline;
    backdrop-filter: blur(50px);
  }
}

.button {
  padding: 0 0.5vw;

  a {
    text-decoration: none;
    color: var(--thirdColor);

    transition: var(--transition);

    &:hover {
      transform: scale(1.05);
      // text-decoration: underline;
      color: var(--secondColor);
    }
  }
}

@media only screen and (max-width: 768px) {
  #buttons {
    font-size: 4vw;
  }
}
