/*  animations */
.fadeout {
  animation: fadeOut2 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut2 {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.gradient-leave {
  background: radial-gradient(
      ellipse at center,
      rgb(255, 255, 255) 0%,
      rgb(0, 0, 0) 90%
    )
    center center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
}

.gradient-leave.visible {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes fadeIn {
  100% {
    opacity: 1;
    visibility: visible;
  }
}

div#slideSource {
  opacity: 1;
  -webkit-transition: opacity 3s;
  -moz-transition: opacity 3s;
  transition: opacity 3s;
}

div#slideSource.fade {
  opacity: 0;
}
