/* .barba-leave-active,
.barba-enter-active {
  transition: opacity 450ms ease;
}

.barba-leave,
.barba-enter-to {
  opacity: 1;
}

.barba-enter,
.barba-leave-to {
  opacity: 0;
}
 */
/* Fade out current container */
/* fade */
.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 1s linear;
}

.fade-leave-to {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s linear;
}

.fade-enter-to {
  opacity: 1;
}
////

.home-leave {
  opacity: 1;
}

.home-leave-active {
  transition: opacity 1s linear;
}

.home-leave-to {
  opacity: 0;
}

.home-enter {
  opacity: 0;
}

.home-enter-active {
  transition: opacity 1s linear;
}

.home-enter-to {
  opacity: 1;
}
